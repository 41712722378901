import { formatApiDateTime, parseApiDateTime } from '@/shared/helper/date';
import type {
  BooleanNaYesNo,
  BooleanNaNoYes,
  RangeNa075100,
  RangeNa07590100,
  RangeNa02575100,
  RangeNa050100,
  RangeNa5075100,
  RangeNa0100,
  StringDevaluationUnitType,
  BooleanDevaluationUnitType,
  NumberNa6,
  AssessmentWagonType,
  RideAssessment,
  ExportRideAssessment,
  ExportDevaluationUnit,
  ExportWagonType
} from '@/shared/modules/AssessmentEdit/types/ride';
import { DevaluationUnit } from '@/shared/modules/AssessmentEdit/types/ride';
import { getImages, saveImages } from '@/shared/modules/AssessmentEdit/services/image-service';

function convertRideAssessmentFromExport(rideAssessmentExport: ExportRideAssessment): RideAssessment {
  return {
    assessmentId: rideAssessmentExport.assessmentId,
    author: rideAssessmentExport.author,
    editor: rideAssessmentExport.editor ?? '',
    assessmentDateTime: parseApiDateTime(rideAssessmentExport.assessmentDateTime) ?? new Date().toISOString(),
    editDateTime: rideAssessmentExport.editDateTime,
    exportDateTime: rideAssessmentExport.exportDateTime,
    isSynchronizing: false,
    assessmentValidationStatus: 'complete',
    forms: {
      baseData: {
        visited: true,
        deactivated: false,
        status: 'complete'
      },
      stationInformation: {
        visited: true,
        deactivated: false,
        status: 'complete'
      },
      wagonState: {
        visited: true,
        deactivated: false,
        status: 'complete'
      },
      conductors: {
        visited: true,
        deactivated: false,
        status: 'complete'
      },
      securityStaff: {
        visited: true,
        deactivated: false,
        status: 'complete'
      },
      onboardService: {
        visited: true,
        deactivated: false,
        status: 'complete'
      },
      utilization: {
        visited: true,
        deactivated: false,
        status: 'complete'
      },
      inTrainInformation: {
        visited: true,
        deactivated: false,
        status: 'complete'
      },
      disruption: {
        visited: true,
        deactivated: false,
        status: 'complete'
      },
      assessmentComment: {
        visited: true,
        deactivated: false,
        status: 'complete'
      },
      wagonTypes: {
        visited: true,
        deactivated: false,
        status: 'complete'
      }
    },
    lineId: rideAssessmentExport.lineId,
    lineName: rideAssessmentExport.lineName,
    scaleType: rideAssessmentExport.scaleType,
    trainNumber: {
      value: rideAssessmentExport.trainNumber,
      status: 'complete',
      errorMessage: undefined,
      deactivated: false
    },
    departureStationId: {
      value: rideAssessmentExport.departureStationId,
      status: 'complete',
      errorMessage: undefined,
      deactivated: false
    },
    departureStationCode: rideAssessmentExport.departureStationCode,
    departureTimeNominal: {
      value: rideAssessmentExport.departureTimeNominal,
      status: 'complete',
      errorMessage: undefined,
      deactivated: false
    },
    departureTimeActual: {
      value: rideAssessmentExport.departureTimeActual,
      status: 'complete',
      errorMessage: undefined,
      deactivated: false
    },
    arrivalStationId: {
      value: rideAssessmentExport.arrivalStationId,
      status: 'complete',
      errorMessage: undefined,
      deactivated: false
    },
    arrivalTimeNominal: {
      value: rideAssessmentExport.arrivalTimeNominal,
      status: 'complete',
      errorMessage: undefined,
      deactivated: false
    },
    arrivalTimeActual: {
      value: rideAssessmentExport.arrivalTimeActual,
      deactivated: false,
      status: 'complete',
      errorMessage: undefined
    },
    cancelled: {
      value: rideAssessmentExport.cancelled,
      status: 'complete',
      errorMessage: undefined
    },
    cancellationComment: {
      note: rideAssessmentExport.cancellationComment.note,
      imageIds: rideAssessmentExport.cancellationComment.images
    },
    stationInformation: [
      convertDevaluationUnitFromExport(
        rideAssessmentExport.stationInformation.stationTrainAnnouncement
      ) as DevaluationUnit<'stationTrainAnnouncement', BooleanNaYesNo>,
      convertDevaluationUnitFromExport(
        rideAssessmentExport.stationInformation.stationDisruptionAnnouncement
      ) as DevaluationUnit<'stationDisruptionAnnouncement', BooleanNaYesNo>,
      convertDevaluationUnitFromExport(
        rideAssessmentExport.stationInformation.stationDestinationDisplay
      ) as DevaluationUnit<'stationDestinationDisplay', RangeNa075100 | RangeNa07590100>,
      convertDevaluationUnitFromExport(
        rideAssessmentExport.stationInformation.stationDisruptionDisplay
      ) as DevaluationUnit<'stationDisruptionDisplay', RangeNa075100 | RangeNa07590100>,
      convertDevaluationUnitFromExport(rideAssessmentExport.stationInformation.trainLabel) as DevaluationUnit<
        'trainLabel',
        RangeNa075100 | RangeNa07590100
      >
    ],
    wagonState: [
      convertDevaluationUnitFromExport(rideAssessmentExport.wagonState.vehicleNumber) as DevaluationUnit<
        'vehicleNumber',
        StringDevaluationUnitType
      >,
      convertDevaluationUnitFromExport(rideAssessmentExport.wagonState.safetyEndangeringDefects) as DevaluationUnit<
        'safetyEndangeringDefects',
        BooleanDevaluationUnitType
      >,
      convertDevaluationUnitFromExport(rideAssessmentExport.wagonState.functionExteriorShell) as DevaluationUnit<
        'functionExteriorShell',
        RangeNa075100 | RangeNa07590100
      >,
      convertDevaluationUnitFromExport(rideAssessmentExport.wagonState.cleanlinessExteriorShell) as DevaluationUnit<
        'cleanlinessExteriorShell',
        RangeNa075100 | RangeNa07590100
      >,
      convertDevaluationUnitFromExport(rideAssessmentExport.wagonState.exteriorGraffiti) as DevaluationUnit<
        'exteriorGraffiti',
        RangeNa075100 | RangeNa07590100
      >,
      convertDevaluationUnitFromExport(rideAssessmentExport.wagonState.functionWindows) as DevaluationUnit<
        'functionWindows',
        RangeNa075100 | RangeNa07590100
      >,
      convertDevaluationUnitFromExport(rideAssessmentExport.wagonState.cleanlinessWindows) as DevaluationUnit<
        'cleanlinessWindows',
        RangeNa075100 | RangeNa07590100
      >,
      convertDevaluationUnitFromExport(rideAssessmentExport.wagonState.functionPassageDoors) as DevaluationUnit<
        'functionPassageDoors',
        RangeNa075100 | RangeNa07590100
      >,
      convertDevaluationUnitFromExport(rideAssessmentExport.wagonState.cleanlinessPassageDoors) as DevaluationUnit<
        'cleanlinessPassageDoors',
        RangeNa075100 | RangeNa07590100
      >,
      convertDevaluationUnitFromExport(rideAssessmentExport.wagonState.functionFloor) as DevaluationUnit<
        'functionFloor',
        RangeNa075100 | RangeNa07590100
      >,
      convertDevaluationUnitFromExport(rideAssessmentExport.wagonState.cleanlinessFloor) as DevaluationUnit<
        'cleanlinessFloor',
        RangeNa075100 | RangeNa07590100
      >,
      convertDevaluationUnitFromExport(rideAssessmentExport.wagonState.functionSeats) as DevaluationUnit<
        'functionSeats',
        RangeNa075100 | RangeNa07590100
      >,
      convertDevaluationUnitFromExport(rideAssessmentExport.wagonState.cleanlinessSeats) as DevaluationUnit<
        'cleanlinessSeats',
        RangeNa075100 | RangeNa07590100
      >,
      convertDevaluationUnitFromExport(rideAssessmentExport.wagonState.functionWasteBins) as DevaluationUnit<
        'functionWasteBins',
        RangeNa075100 | RangeNa07590100
      >,
      convertDevaluationUnitFromExport(rideAssessmentExport.wagonState.cleanlinessWasteBins) as DevaluationUnit<
        'cleanlinessWasteBins',
        RangeNa075100 | RangeNa07590100
      >,
      convertDevaluationUnitFromExport(rideAssessmentExport.wagonState.functionInteriorScaffold) as DevaluationUnit<
        'functionInteriorScaffold',
        RangeNa075100 | RangeNa07590100
      >,
      convertDevaluationUnitFromExport(rideAssessmentExport.wagonState.cleanlinessInteriorScaffold) as DevaluationUnit<
        'cleanlinessInteriorScaffold',
        RangeNa075100 | RangeNa07590100
      >,
      convertDevaluationUnitFromExport(rideAssessmentExport.wagonState.interiorGraffiti) as DevaluationUnit<
        'interiorGraffiti',
        RangeNa075100 | RangeNa07590100
      >,
      convertDevaluationUnitFromExport(rideAssessmentExport.wagonState.functionLights) as DevaluationUnit<
        'functionLights',
        RangeNa075100 | RangeNa07590100
      >,
      convertDevaluationUnitFromExport(rideAssessmentExport.wagonState.functionHeating) as DevaluationUnit<
        'functionHeating',
        RangeNa0100
      >,
      convertDevaluationUnitFromExport(rideAssessmentExport.wagonState.functionToilets) as DevaluationUnit<
        'functionToilets',
        RangeNa075100 | RangeNa07590100
      >,
      convertDevaluationUnitFromExport(rideAssessmentExport.wagonState.cleanlinessToilets) as DevaluationUnit<
        'cleanlinessToilets',
        RangeNa075100 | RangeNa07590100
      >,
      convertDevaluationUnitFromExport(rideAssessmentExport.wagonState.functionExteriorDoors) as DevaluationUnit<
        'functionExteriorDoors',
        RangeNa075100 | RangeNa07590100
      >,
      convertDevaluationUnitFromExport(rideAssessmentExport.wagonState.cleanlinessExteriorDoors) as DevaluationUnit<
        'cleanlinessExteriorDoors',
        RangeNa075100 | RangeNa07590100
      >
    ],
    conductors: [
      convertDevaluationUnitFromExport(rideAssessmentExport.conductors.conductorCount) as DevaluationUnit<
        'conductorCount',
        NumberNa6
      >,
      convertDevaluationUnitFromExport(rideAssessmentExport.conductors.conductorInactivity) as DevaluationUnit<
        'conductorInactivity',
        BooleanNaNoYes
      >,
      convertDevaluationUnitFromExport(rideAssessmentExport.conductors.conductorAppearance) as DevaluationUnit<
        'conductorAppearance',
        RangeNa050100
      >,
      convertDevaluationUnitFromExport(rideAssessmentExport.conductors.conductorBehavior) as DevaluationUnit<
        'conductorBehavior',
        RangeNa02575100
      >
    ],
    securityStaff: [
      convertDevaluationUnitFromExport(rideAssessmentExport.securityStaff.securityStaffCount) as DevaluationUnit<
        'securityStaffCount',
        NumberNa6
      >,
      convertDevaluationUnitFromExport(rideAssessmentExport.securityStaff.securityStaffInactivity) as DevaluationUnit<
        'securityStaffInactivity',
        BooleanNaNoYes
      >,
      convertDevaluationUnitFromExport(rideAssessmentExport.securityStaff.securityStaffAppearance) as DevaluationUnit<
        'securityStaffAppearance',
        RangeNa050100
      >,
      convertDevaluationUnitFromExport(rideAssessmentExport.securityStaff.securityStaffBehavior) as DevaluationUnit<
        'securityStaffBehavior',
        RangeNa02575100
      >
    ],
    onboardService: [
      convertDevaluationUnitFromExport(rideAssessmentExport.onboardService.onboardServiceCount) as DevaluationUnit<
        'onboardServiceCount',
        NumberNa6
      >,
      convertDevaluationUnitFromExport(rideAssessmentExport.onboardService.onboardServiceBehavior) as DevaluationUnit<
        'onboardServiceBehavior',
        RangeNa02575100
      >
    ],
    utilization: [
      convertDevaluationUnitFromExport(rideAssessmentExport.utilization.seats) as DevaluationUnit<
        'seats',
        RangeNa5075100
      >,
      convertDevaluationUnitFromExport(rideAssessmentExport.utilization.standingRoom) as DevaluationUnit<
        'standingRoom',
        RangeNa5075100
      >
    ],
    inTrainInformation: [
      convertDevaluationUnitFromExport(rideAssessmentExport.inTrainInformation.routeNetworkPlan) as DevaluationUnit<
        'routeNetworkPlan',
        RangeNa075100 | RangeNa07590100
      >,
      convertDevaluationUnitFromExport(rideAssessmentExport.inTrainInformation.speakerAnnouncement) as DevaluationUnit<
        'speakerAnnouncement',
        RangeNa075100 | RangeNa07590100
      >,
      convertDevaluationUnitFromExport(
        rideAssessmentExport.inTrainInformation.passengerInformationSystem
      ) as DevaluationUnit<'passengerInformationSystem', RangeNa075100 | RangeNa07590100>
    ],
    disruption: [
      convertDevaluationUnitFromExport(rideAssessmentExport.disruption.disrupted) as DevaluationUnit<
        'disrupted',
        BooleanDevaluationUnitType
      >,
      convertDevaluationUnitFromExport(rideAssessmentExport.disruption.disruptionAnnouncement) as DevaluationUnit<
        'disruptionAnnouncement',
        BooleanNaYesNo
      >,
      convertDevaluationUnitFromExport(rideAssessmentExport.disruption.disruptionStartStationId) as DevaluationUnit<
        'disruptionStartStationId',
        StringDevaluationUnitType
      >,
      convertDevaluationUnitFromExport(rideAssessmentExport.disruption.disruptionEndStationId) as DevaluationUnit<
        'disruptionEndStationId',
        StringDevaluationUnitType
      >
    ],
    assessmentComment: {
      noComment:
        rideAssessmentExport.assessmentComment.note === '' &&
        rideAssessmentExport.assessmentComment.images.length === 0,
      note: rideAssessmentExport.assessmentComment.note,
      imageIds: rideAssessmentExport.assessmentComment.images,
      deactivated: false,
      status: 'complete'
    },
    wagonTypes: {
      deactivated: false,
      values: convertWagonTypesFromExport(rideAssessmentExport.wagonTypes),
      status: 'complete'
    },
    assessedWagonTypeId: rideAssessmentExport.assessedWagonTypeId,
    assessedWagonTypeName: rideAssessmentExport.assessedWagonTypeName,
    revisionComment: rideAssessmentExport.revisionComment ?? ''
  };
}

function convertRideAssessmentToExport(rideAssessment: RideAssessment): ExportRideAssessment {
  return {
    assessmentId: rideAssessment.assessmentId,
    assessmentDateTime: formatApiDateTime(rideAssessment.assessmentDateTime),
    editDateTime: rideAssessment.editDateTime,
    exportDateTime: rideAssessment.exportDateTime,
    lineId: rideAssessment.lineId,
    lineName: rideAssessment.lineName,
    scaleType: rideAssessment.scaleType,
    trainNumber: rideAssessment.trainNumber.value,
    departureStationId: rideAssessment.departureStationId.value,
    departureStationCode: rideAssessment.departureStationCode,
    departureTimeNominal: rideAssessment.departureTimeNominal.value,
    departureTimeActual: rideAssessment.departureTimeActual.value,
    arrivalStationId: rideAssessment.arrivalStationId.value,
    arrivalTimeNominal: rideAssessment.arrivalTimeNominal.value,
    arrivalTimeActual: rideAssessment.arrivalTimeActual.value,
    cancelled: rideAssessment.cancelled.value,
    cancellationComment: {
      note: rideAssessment.cancellationComment.note,
      images: rideAssessment.cancellationComment.imageIds
    },
    stationInformation: {
      stationTrainAnnouncement: convertDevaluationUnitToExport(
        rideAssessment.stationInformation[0]
      ) as ExportDevaluationUnit<'stationTrainAnnouncement', BooleanNaYesNo>,
      stationDisruptionAnnouncement: convertDevaluationUnitToExport(
        rideAssessment.stationInformation[1]
      ) as ExportDevaluationUnit<'stationDisruptionAnnouncement', BooleanNaYesNo>,
      stationDestinationDisplay: convertDevaluationUnitToExport(
        rideAssessment.stationInformation[2]
      ) as ExportDevaluationUnit<'stationDestinationDisplay', RangeNa075100 | RangeNa07590100>,
      stationDisruptionDisplay: convertDevaluationUnitToExport(
        rideAssessment.stationInformation[3]
      ) as ExportDevaluationUnit<'stationDisruptionDisplay', RangeNa075100 | RangeNa07590100>,
      trainLabel: convertDevaluationUnitToExport(rideAssessment.stationInformation[4]) as ExportDevaluationUnit<
        'trainLabel',
        RangeNa075100 | RangeNa07590100
      >
    },
    wagonState: {
      vehicleNumber: convertDevaluationUnitToExport(rideAssessment.wagonState[0]) as ExportDevaluationUnit<
        'vehicleNumber',
        StringDevaluationUnitType
      >,
      safetyEndangeringDefects: convertDevaluationUnitToExport(rideAssessment.wagonState[1]) as ExportDevaluationUnit<
        'safetyEndangeringDefects',
        BooleanDevaluationUnitType
      >,
      functionExteriorShell: convertDevaluationUnitToExport(rideAssessment.wagonState[2]) as ExportDevaluationUnit<
        'functionExteriorShell',
        RangeNa075100 | RangeNa07590100
      >,
      cleanlinessExteriorShell: convertDevaluationUnitToExport(rideAssessment.wagonState[3]) as ExportDevaluationUnit<
        'cleanlinessExteriorShell',
        RangeNa075100 | RangeNa07590100
      >,
      exteriorGraffiti: convertDevaluationUnitToExport(rideAssessment.wagonState[4]) as ExportDevaluationUnit<
        'exteriorGraffiti',
        RangeNa075100 | RangeNa07590100
      >,
      functionWindows: convertDevaluationUnitToExport(rideAssessment.wagonState[5]) as ExportDevaluationUnit<
        'functionWindows',
        RangeNa075100 | RangeNa07590100
      >,
      cleanlinessWindows: convertDevaluationUnitToExport(rideAssessment.wagonState[6]) as ExportDevaluationUnit<
        'cleanlinessWindows',
        RangeNa075100 | RangeNa07590100
      >,
      functionPassageDoors: convertDevaluationUnitToExport(rideAssessment.wagonState[7]) as ExportDevaluationUnit<
        'functionPassageDoors',
        RangeNa075100 | RangeNa07590100
      >,
      cleanlinessPassageDoors: convertDevaluationUnitToExport(rideAssessment.wagonState[8]) as ExportDevaluationUnit<
        'cleanlinessPassageDoors',
        RangeNa075100 | RangeNa07590100
      >,
      functionFloor: convertDevaluationUnitToExport(rideAssessment.wagonState[9]) as ExportDevaluationUnit<
        'functionFloor',
        RangeNa075100 | RangeNa07590100
      >,
      cleanlinessFloor: convertDevaluationUnitToExport(rideAssessment.wagonState[10]) as ExportDevaluationUnit<
        'cleanlinessFloor',
        RangeNa075100 | RangeNa07590100
      >,
      functionSeats: convertDevaluationUnitToExport(rideAssessment.wagonState[11]) as ExportDevaluationUnit<
        'functionSeats',
        RangeNa075100 | RangeNa07590100
      >,
      cleanlinessSeats: convertDevaluationUnitToExport(rideAssessment.wagonState[12]) as ExportDevaluationUnit<
        'cleanlinessSeats',
        RangeNa075100 | RangeNa07590100
      >,
      functionWasteBins: convertDevaluationUnitToExport(rideAssessment.wagonState[13]) as ExportDevaluationUnit<
        'functionWasteBins',
        RangeNa075100 | RangeNa07590100
      >,
      cleanlinessWasteBins: convertDevaluationUnitToExport(rideAssessment.wagonState[14]) as ExportDevaluationUnit<
        'cleanlinessWasteBins',
        RangeNa075100 | RangeNa07590100
      >,
      functionInteriorScaffold: convertDevaluationUnitToExport(rideAssessment.wagonState[15]) as ExportDevaluationUnit<
        'functionInteriorScaffold',
        RangeNa075100 | RangeNa07590100
      >,
      cleanlinessInteriorScaffold: convertDevaluationUnitToExport(
        rideAssessment.wagonState[16]
      ) as ExportDevaluationUnit<'cleanlinessInteriorScaffold', RangeNa075100 | RangeNa07590100>,
      interiorGraffiti: convertDevaluationUnitToExport(rideAssessment.wagonState[17]) as ExportDevaluationUnit<
        'interiorGraffiti',
        RangeNa075100 | RangeNa07590100
      >,
      functionLights: convertDevaluationUnitToExport(rideAssessment.wagonState[18]) as ExportDevaluationUnit<
        'functionLights',
        RangeNa075100 | RangeNa07590100
      >,
      functionHeating: convertDevaluationUnitToExport(rideAssessment.wagonState[19]) as ExportDevaluationUnit<
        'functionHeating',
        RangeNa0100
      >,
      functionToilets: convertDevaluationUnitToExport(rideAssessment.wagonState[20]) as ExportDevaluationUnit<
        'functionToilets',
        RangeNa075100 | RangeNa07590100
      >,
      cleanlinessToilets: convertDevaluationUnitToExport(rideAssessment.wagonState[21]) as ExportDevaluationUnit<
        'cleanlinessToilets',
        RangeNa075100 | RangeNa07590100
      >,
      functionExteriorDoors: convertDevaluationUnitToExport(rideAssessment.wagonState[22]) as ExportDevaluationUnit<
        'functionExteriorDoors',
        RangeNa075100 | RangeNa07590100
      >,
      cleanlinessExteriorDoors: convertDevaluationUnitToExport(rideAssessment.wagonState[23]) as ExportDevaluationUnit<
        'cleanlinessExteriorDoors',
        RangeNa075100 | RangeNa07590100
      >
    },
    conductors: {
      conductorCount: convertDevaluationUnitToExport(rideAssessment.conductors[0]) as ExportDevaluationUnit<
        'conductorCount',
        NumberNa6
      >,
      conductorInactivity: convertDevaluationUnitToExport(rideAssessment.conductors[1]) as ExportDevaluationUnit<
        'conductorInactivity',
        BooleanNaNoYes
      >,
      conductorAppearance: convertDevaluationUnitToExport(rideAssessment.conductors[2]) as ExportDevaluationUnit<
        'conductorAppearance',
        RangeNa050100
      >,
      conductorBehavior: convertDevaluationUnitToExport(rideAssessment.conductors[3]) as ExportDevaluationUnit<
        'conductorBehavior',
        RangeNa02575100
      >
    },
    securityStaff: {
      securityStaffCount: convertDevaluationUnitToExport(rideAssessment.securityStaff[0]) as ExportDevaluationUnit<
        'securityStaffCount',
        NumberNa6
      >,
      securityStaffInactivity: convertDevaluationUnitToExport(rideAssessment.securityStaff[1]) as ExportDevaluationUnit<
        'securityStaffInactivity',
        BooleanNaNoYes
      >,
      securityStaffAppearance: convertDevaluationUnitToExport(rideAssessment.securityStaff[2]) as ExportDevaluationUnit<
        'securityStaffAppearance',
        RangeNa050100
      >,
      securityStaffBehavior: convertDevaluationUnitToExport(rideAssessment.securityStaff[3]) as ExportDevaluationUnit<
        'securityStaffBehavior',
        RangeNa02575100
      >
    },
    onboardService: {
      onboardServiceCount: convertDevaluationUnitToExport(rideAssessment.onboardService[0]) as ExportDevaluationUnit<
        'onboardServiceCount',
        NumberNa6
      >,
      onboardServiceBehavior: convertDevaluationUnitToExport(rideAssessment.onboardService[1]) as ExportDevaluationUnit<
        'onboardServiceBehavior',
        RangeNa02575100
      >
    },
    utilization: {
      seats: convertDevaluationUnitToExport(rideAssessment.utilization[0]) as ExportDevaluationUnit<
        'seats',
        RangeNa5075100
      >,
      standingRoom: convertDevaluationUnitToExport(rideAssessment.utilization[1]) as ExportDevaluationUnit<
        'standingRoom',
        RangeNa5075100
      >
    },
    inTrainInformation: {
      routeNetworkPlan: convertDevaluationUnitToExport(rideAssessment.inTrainInformation[0]) as ExportDevaluationUnit<
        'routeNetworkPlan',
        RangeNa075100 | RangeNa07590100
      >,
      speakerAnnouncement: convertDevaluationUnitToExport(
        rideAssessment.inTrainInformation[1]
      ) as ExportDevaluationUnit<'speakerAnnouncement', RangeNa075100 | RangeNa07590100>,
      passengerInformationSystem: convertDevaluationUnitToExport(
        rideAssessment.inTrainInformation[2]
      ) as ExportDevaluationUnit<'passengerInformationSystem', RangeNa075100 | RangeNa07590100>
    },
    disruption: {
      disrupted: convertDevaluationUnitToExport(rideAssessment.disruption[0]) as ExportDevaluationUnit<
        'disrupted',
        BooleanDevaluationUnitType
      >,
      disruptionAnnouncement: convertDevaluationUnitToExport(rideAssessment.disruption[1]) as ExportDevaluationUnit<
        'disruptionAnnouncement',
        BooleanNaYesNo
      >,
      disruptionStartStationId: convertDevaluationUnitToExport(rideAssessment.disruption[2]) as ExportDevaluationUnit<
        'disruptionStartStationId',
        StringDevaluationUnitType
      >,
      disruptionEndStationId: convertDevaluationUnitToExport(rideAssessment.disruption[3]) as ExportDevaluationUnit<
        'disruptionEndStationId',
        StringDevaluationUnitType
      >
    },
    assessmentComment: {
      note: rideAssessment.assessmentComment.note,
      images: rideAssessment.assessmentComment.imageIds
    },
    wagonTypes: convertWagonTypesToExport(rideAssessment.wagonTypes.values),
    assessedWagonTypeId: rideAssessment.assessedWagonTypeId,
    assessedWagonTypeName: rideAssessment.assessedWagonTypeName
  };
}

function convertDevaluationUnitFromExport(devaluationUnit: ExportDevaluationUnit) {
  return {
    name: devaluationUnit.name,
    type: devaluationUnit.type,
    value: convertValueFromExport(devaluationUnit.value),
    predefinedNotes: devaluationUnit.comments,
    note: devaluationUnit.note,
    imageIds: devaluationUnit.images,
    deactivated: false,
    status: 'complete',
    errorMessage: undefined
  };
}

function convertDevaluationUnitToExport(devaluationUnit: DevaluationUnit): ExportDevaluationUnit {
  return {
    name: devaluationUnit.name,
    type: devaluationUnit.type,
    value: convertValueToExport(devaluationUnit.value),
    comments: devaluationUnit.predefinedNotes,
    note: devaluationUnit.note,
    images: devaluationUnit.imageIds
  };
}

function convertValueFromExport(value: string | null | undefined): string | undefined {
  if ('false' === value) {
    return 'no';
  }

  if ('true' === value) {
    return 'yes';
  }

  if (null === value) {
    return undefined;
  }

  return value;
}

function convertValueToExport(value: string | undefined): string | null {
  if ('no' === value) {
    return 'false';
  }

  if ('yes' === value) {
    return 'true';
  }

  if (undefined === value) {
    return null;
  }

  return value;
}

function convertWagonTypesFromExport(wagonTypes: ExportWagonType[]): AssessmentWagonType[] {
  return wagonTypes.map((wagonType: ExportWagonType): AssessmentWagonType => {
    return {
      wagonTypeId: wagonType.wagonTypeId,
      vehicleNumbers: wagonType.vehicleNumbers,
      count: wagonType.count,
      note: wagonType.note,
      imageIds: wagonType.images
    };
  });
}

function convertWagonTypesToExport(wagonTypes: AssessmentWagonType[]): ExportWagonType[] {
  const unifiedWagonTypes: ExportWagonType[] = [];
  wagonTypes.map((wagonType: AssessmentWagonType) => {
    const existingWagonType = unifiedWagonTypes.find(
      unifiedWagonType => unifiedWagonType.wagonTypeId === wagonType.wagonTypeId
    );

    if (existingWagonType) {
      unifiedWagonTypes[unifiedWagonTypes.indexOf(existingWagonType)] = {
        wagonTypeId: wagonType.wagonTypeId,
        vehicleNumbers: [...existingWagonType.vehicleNumbers, ...wagonType.vehicleNumbers],
        count: existingWagonType.count + wagonType.count,
        note: `${existingWagonType.note}, ${wagonType.note}`,
        images: [...existingWagonType.images, ...wagonType.imageIds]
      };
    } else {
      unifiedWagonTypes.push({
        wagonTypeId: wagonType.wagonTypeId,
        vehicleNumbers: wagonType.vehicleNumbers,
        count: wagonType.count,
        note: wagonType.note,
        images: wagonType.imageIds
      });
    }
  });

  return unifiedWagonTypes;
}

async function replaceImagesWithIds(rideAssessment: RideAssessment): Promise<void> {
  for (const unit of [
    ...Object.values(rideAssessment.stationInformation),
    ...Object.values(rideAssessment.wagonState),
    ...Object.values(rideAssessment.conductors),
    ...Object.values(rideAssessment.securityStaff),
    ...Object.values(rideAssessment.onboardService),
    ...Object.values(rideAssessment.utilization),
    ...Object.values(rideAssessment.inTrainInformation),
    ...Object.values(rideAssessment.disruption),
    ...rideAssessment.wagonTypes.values,
    rideAssessment.assessmentComment,
    rideAssessment.cancellationComment
  ]) {
    unit.imageIds = await saveImagesOrThrow(unit.imageIds);
  }
}

async function replaceIdsWithImages(exportRideAssessment: ExportRideAssessment): Promise<void> {
  for (const unit of [
    ...Object.values(exportRideAssessment.stationInformation),
    ...Object.values(exportRideAssessment.wagonState),
    ...Object.values(exportRideAssessment.conductors),
    ...Object.values(exportRideAssessment.securityStaff),
    ...Object.values(exportRideAssessment.onboardService),
    ...Object.values(exportRideAssessment.utilization),
    ...Object.values(exportRideAssessment.inTrainInformation),
    ...Object.values(exportRideAssessment.disruption),
    ...exportRideAssessment.wagonTypes,
    exportRideAssessment.assessmentComment,
    exportRideAssessment.cancellationComment
  ]) {
    unit.images = await getImages(unit.images);
  }
}

async function saveImagesOrThrow(images: string[]) {
  const result = await saveImages(images, 'ride-assessment-edit-');
  if (!result.success) {
    throw result.error;
  }
  return result.ids;
}

export { convertRideAssessmentFromExport, convertRideAssessmentToExport, replaceIdsWithImages, replaceImagesWithIds };
